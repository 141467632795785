// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-components-blog-post-index-js": () => import("../src/components/BlogPost/index.js" /* webpackChunkName: "component---src-components-blog-post-index-js" */),
  "component---src-pages-404-index-js": () => import("../src/pages/404/index.js" /* webpackChunkName: "component---src-pages-404-index-js" */),
  "component---src-pages-blog-index-js": () => import("../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-index-js": () => import("../src/pages/projects/index.js" /* webpackChunkName: "component---src-pages-projects-index-js" */)
}

